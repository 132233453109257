// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-offer-page-js": () => import("/opt/build/repo/src/templates/offer-page.js" /* webpackChunkName: "component---src-templates-offer-page-js" */),
  "component---src-templates-partners-page-js": () => import("/opt/build/repo/src/templates/partners-page.js" /* webpackChunkName: "component---src-templates-partners-page-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-partner-js": () => import("/opt/build/repo/src/pages/contact/partner.js" /* webpackChunkName: "component---src-pages-contact-partner-js" */),
  "component---src-pages-contact-startup-js": () => import("/opt/build/repo/src/pages/contact/startup.js" /* webpackChunkName: "component---src-pages-contact-startup-js" */),
  "component---src-pages-contact-thanks-js": () => import("/opt/build/repo/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

